import React from "react"

import { Col } from 'reactstrap';

import MovieCardLargeList from "../components/MovieCardLargeList"

import { Link } from "gatsby"
import { parseUrlParams } from "../utils";
import { getWatchlistFromLocalstorage } from "../utils/localStorage";
import { getToken } from "../api";

class ExploreBody extends React.Component {

  state = {
    movies: [],
    count: null,
    page: "1",
    resultsPerPage: 20,
    watchlist: [],
    errorMessage: null,
  }

  async componentDidMount(){
    // if(this.props.pageContext.streaming) return
    this.parseUrlAndUpdateState()
    const watchlist = await getWatchlistFromLocalstorage()
    this.setState({watchlist})
  }
  async componentDidUpdate(prevProps, prevState){
    // if(this.props.pageContext.streaming) return
    const page = parseUrlParams(this.props.search).page
    if(page !== this.state.page && !this.state.startFetching && page) {
      await this.parseUrlAndUpdateState()
    }
  }
  
  parseUrlAndUpdateState = () => {
    const params = parseUrlParams(this.props.search);
    const page = params.page
    this.setState({ page: page || "1", startFetching: true, movies: []});
    const { pageContext } = this.props
    const genre = pageContext.genre
    let year = null
    let streaming = pageContext.streaming
    let order = pageContext.sortOrder
    if (pageContext.year !== 'all-time') {
      year = pageContext.year
      order = 'revenue'
    } else {
      order = 'revenue'
    }

    this.fetchPage(page, genre, order, year, streaming);

  }
  
  fetchPage = async (page = "1", genre = null, order = null, year=null, streaming=null) => {
    const {movies, next} = this.state
    let params = '?'
    if (genre === 'disney-plus') {
      params += `&watch_on=${1}`
    } else if (genre === 'netflix') {
      params += `&watch_on=${7}`
    } else if (genre === 'amazon-prime-video') {
      params += `&watch_on=${8}`
    } else if (genre && genre !== 'all') {
      params += `&genre=${genre}`
    }
    if (order) params += `&order=${order}`
    if (year) params += `&year=${year}`
    if (streaming) params += `&streaming=${streaming}`
    if (page) params += `&limit=${this.state.resultsPerPage}&offset=${(page - 1) * this.state.resultsPerPage}`

    let url = `${process.env.GATSBY_API_LIVE_URL}/fr/movie${params}`

    const userToken = await getToken()
    const headers = {}
    if (userToken !== null){
      headers['Authorization'] = `Token ${userToken}`
    }

    const response = await fetch(url, {
      method: 'GET',
      headers: headers,
    })

    if (response.ok) {
      const result = await response.json()
      if (!result.next && !result.previous && !result.count) {
        this.setState({errorMessage: `Pas de résultats"`,  startFetching: false})
        return
      }
      this.setState({
        movies: [...result.results],
        count: result.count,
        next: result.next,
        isLoading: false,
        startFetching: false,
        errorMessage: null,
      });
      return
    }
    if (!this.state.movies.length) {
      this.setState({
        errorMessage: "Cette page semble vide... Essayez de changer les filtres -->"
      })
    }
    const errMessage = await response.text()
    return (errMessage)
  }

  render(){
    if (!this.state.movies) return ''
    const { pageContext, data, path } = this.props

    const { genre, streaming } = pageContext
    let totalCount = this.state.count //|| data.allInternalMovies.totalCount

    // let movies = data.allInternalMovies.edges
    // // replace 'node' key by 'movie'
    // movies = streaming 
    // ? movies.map(item => item.node) 
    // : this.state.movies;

    let {movies, watchlist} = this.state
  
    let page = parseInt(this.state.page)
    let totalPages = Math.ceil(totalCount / this.state.resultsPerPage)
    let pages = []
    for(let i = page - 1; i > page - 5 && i >= 1; i--){
      pages.unshift(<li className={`page-item ${i === page ? 'disabled' : ''}`}><Link to={`${path}?page=${i}`} className="page-link" aria-disabled={page === i}>{i}</Link></li>)
    }
    for(let i = page; i < page + 5 && i <= totalPages; i++){
      pages.push(<li className={`page-item ${i === page ? 'disabled' : ''}`}><Link to={`${path}?page=${i}`} className="page-link" aria-disabled={page === i}>{i}</Link></li>)
    }
  
    return (
        <Col md="8">
            {!this.state.errorMessage ?
              <>
                <MovieCardLargeList movies={movies} watchlist={watchlist}/>
                <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-center">
                    <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                        <Link to={`${path}?page=${page - 1}`} className="page-link" aria-disabled={page === 1}>&laquo;</Link>
                    </li>
                    { pages }
                    <li className={`page-item ${page === totalPages || totalPages === 0 ? 'disabled' : ''}`}>
                        <Link to={`${path}?page=${page + 1}`} className="page-link" aria-disabled={page === totalPages || totalPages === 0}>&raquo;</Link>
                    </li>
                    </ul>
                </nav>
              </>
              : <div className="text-secondary mt-4 text-center">{this.state.errorMessage}</div>
            }

        </Col>
    )
  }
}


export default ExploreBody