import React, { Suspense } from "react"
import PropTypes from "prop-types"
import {Spinner, Col, Container, Row} from 'reactstrap'

import Layout from '../components/Layout'

import { graphql } from "gatsby"

import ExploreTitle from '../components/ExploreTitle'

import ExploreSidebar from "../components/ExploreSidebar"
import ExploreBody from "../components/ExploreBody"
import { LogPageView } from "../components/LogEvents"

// const ExploreSidebar = React.lazy(() => import("../components/ExploreSidebar"));
// const ExploreBody = React.lazy(() => import("../components/ExploreBody"));


class Explore extends React.Component {

  state = {
    count: null,
  }

  render(){
    const { pageContext, data, path } = this.props

    const { genre, streaming } = pageContext
    let totalCount = this.state.count //|| data.allInternalMovies.totalCount

    return (
      <Layout>
      <Container>
        <ExploreTitle genre={genre} streaming={streaming} totalCount={totalCount} pageContext={pageContext}/>
        <LogPageView event="pageview" slug={path} itemName="ExplorePage" value={null} itemId={null} />
        <Row className="mt-3">
          {/* <Suspense fallback={<Col md="8" className="m-auto text-center"> <Spinner className="m-auto text-center" size="sm"/> </Col>}> */}
            <ExploreBody pageContext={pageContext} data={data} path={path} search={this.props.location.search}/>
          {/* </Suspense> */}
          {/* <Suspense fallback={<Col md="4" className="m-auto text-center"> <Spinner className="m-auto text-center" size="sm"/> </Col>}> */}
            <ExploreSidebar pageContext={pageContext} genre={genre} />
          {/* </Suspense> */}
        </Row>
      </Container>
    </Layout>
    )
  }
}


Explore.propTypes = {
  pageContext: PropTypes.shape({
    genre: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Explore

// export const pageQuery = graphql`
//   query($filter: internal__moviesFilterInput, $sortField: [internal__moviesFieldsEnum]!, $sortOrder: [SortOrderEnum]) {
//     allInternalMovies(
//       limit: 350
//       sort: {
//         fields: $sortField, 
//         order: $sortOrder
//       }, 
//       filter: $filter
//     ) {
//       totalCount
//       edges {
//         node {
//           id
//           title
//           slug
//           synopsis
//           is_streaming
//           avg_rating
//           total_ratings
//           movie {
//             genres
//             runtime
//           }
//           poster_thumb {
//             x
//             y
//             url
//           }
//         }
//       }
//     }
//   }
// `