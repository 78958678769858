export const searchTitleGoodForSeo = [
  "Meilleurs films de tous les temps",
  "Meilleurs films d'action de tous les temps",
  "Meilleurs films d'aventure de tous les temps",
  "180 Films du domaine public à voir en streaming",
  "Meilleurs films français à voir en streaming",
  "Meilleurs films français de tous les temps",
  "Meilleurs films de 2018",
]

export const EXPLORE_SEO_DATA = {
  'all': {title: 'films', description: ''},
  'Action': {title: 'films d\'action', description: ''},
  'Adventure': {title: 'films d\'aventure', description: ''},
  'Animation': {title: 'films d\'animation', description: ''},
  'Biography': {title: 'films biographiques', description: ''},
  'Comedy': {title: 'comédies', description: ''},
  'Crime': {title: 'films policier', description: ''},
  'Documentary': {title: 'documentaires', description: ''},
  'Drama': {title: 'films dramatiques', description: ''},
  'Family': {title: 'films à voir en famille', description: ''},
  'Fantasy': {title: 'films fantastiques', description: ''},
  'Film-Noir': {title: 'films noirs', description: ''},
  'History': {title: 'films historiques', description: ''},
  'Horror': {title: 'films d\'horreur ', description: ''},
  'Music': {title: 'films de musique', description: ''},
  'Musical': {title: 'films musicaux', description: ''},
  'Mystery': {title: 'films à suspense / mystère', description: ''},
  'Romance': {title: 'films romantiques', description: ''},
  'Sci-Fi': {title: 'films de science fiction', description: ''},
  'Short': {title: 'courts métrages', description: ''},
  'Sport': {title: 'films sur le sport', description: ''},
  'Thriller': {title: 'thrillers', description: ''},
  'War': {title: 'films de guerre', description: ''},
  'Western': {title: 'westerns', description: ''},
}

/*
 * Keywords to rank for
 *
 */

const keywords = [
  '',
  '',
  '',
  '',
  '',
  '',
  '',
]

// films gratuit
// film gratuit

// film complet en francais
// film francais
// film aventure