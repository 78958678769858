import React from "react"

import {GENRES_EN} from '../const'
import { EXPLORE_SEO_DATA } from '../utils/text'
import Metadata from "./Metadata"

class ExploreTitle extends React.Component {

  state = {

  }

  render(){
    const { genre, streaming, totalCount, pageContext } = this.props
  
    // Craft the title
    let title = ''
    let description = ''
    let rawDescription = ''
    let streamingTxt = ''
    if (genre === 'disney-plus') {
      title = `Disney+: Catalogue complet de films en France`
      description = (<span>Catalogue complet des films annoncés sur disney plus en France jusqu'a présent (<a href="https://newsroom.disney.fr/assets/liste-contenus-disneyplus-060320-pdf-a0b8-dc71f.html?lang=fr">Liste complète</a>) </span>)
      rawDescription = "Catalogue complet des films annoncés sur disney plus en France jusqu'a présent"
    } else if (genre === 'amazon-prime-video') {
      title = `Amazon Prime Video: Catalogue complet de films en France`
      description = (<span>Catalogue complet des films annoncés sur Amazon Prime Video en France jusqu'a présent </span>)
      rawDescription = "Catalogue complet des films annoncés sur Amazon Prime Video en France jusqu'a présent"
    } else if (genre === 'netflix') {
      title = `Netflix: Catalogue complet de films en France`
      description = (<span>Catalogue complet des films annoncés sur netflix plus en France jusqu'a présent </span>)
      rawDescription = "Catalogue complet des films annoncés sur netflix plus en France jusqu'a présent"
    } else {
      const order = pageContext.sortOrder === 'DESC' ? 'meilleurs' : 'pires'

      let year = ""
      if (streaming && !pageContext.year) year = 'du domaine public'
      else if (pageContext.year === 'all-time') year = 'de tous les temps' 
      else year = `de ${pageContext.year}`

      let genreTitle = genre === 'all' ? EXPLORE_SEO_DATA[genre].title : EXPLORE_SEO_DATA[GENRES_EN[genre]].title
      if(genreTitle === "films d'animation"){
        genreTitle = 'dessins animés'
      }
      streamingTxt = streaming ? 'à voir gratuitement' : ''
      title = `Les ${order} ${genreTitle} ${year}`
      description = `Quels sont les ${order.toLowerCase()} ${genreTitle} ${streaming ? 'du domaine public ' + streamingTxt : year} ? Découvrez les  ${order.toLowerCase()} ${genreTitle} ${year} ${streamingTxt} d'après les notes des spectateurs` //${totalCount}
      rawDescription = description
    }

    return (
        <>
          <Metadata title={title} description={rawDescription}/>
          <div className="bg-white px-4 py-3 mb-3 mt-3">
            <h1 className="font-weight-bold text-dark text-center"> {title} <br/> {streamingTxt}</h1>
            <p className="text-center">{description}</p>
          </div>
        </>
    )
  }
}

export default ExploreTitle