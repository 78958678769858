import React, { useState, useEffect } from 'react'
import { usePageVisibility } from './hooks/usePageVisibility'
import { logEvent } from '../api'

export const LogPageView = ({event, slug, itemName, value, itemId}) => {
  const isVisible = usePageVisibility();
  let interval = setInterval(() => {}, 99999999999999);
  // Send pageview
  useEffect(() => {
      logEvent(event, slug, itemName, value, itemId)
  },[])
  // send heartbeat
  useEffect(() => {
    if(isVisible) {
      clearInterval(interval)
      interval = setInterval(() => {
        logEvent("heartbeat", slug, itemName, 30, itemId);
      }, 30000)
      return () => { clearInterval(interval) }
    } else {
      clearInterval(interval)
    }
  })
  return <span></span>
}