import React from "react"

import {GENRES_TABLE_FR, GENRES_TABLE} from '../const'
import { navigate } from "gatsby"
import AdComponent from "./AdComponent"
import { Col } from "reactstrap"

class ExploreSidebar extends React.Component {

  state = {

  }

  render(){
    const { pageContext } = this.props
    let { year, genre, streaming } = pageContext

    year = year === "all-time" ? "tous-les-temps" : year;

    const years = []
    const y = new Date().getFullYear()
    for(let i = 2000; i <= y; i++) { 
        years.push(i.toString())
    }

    const notgenres = ['all', 'amazon-prime-video','netflix','disney-plus']

    return (
        <Col md="4">

            <div className="bg-white px-4 py-3 mb-3">
                <h6 className="font-weight-bold text-dark">Service de streaming</h6>
                <ul className="list-unstyled">
                    <button type="button" className={`btn btn-sm btn-light mr-1 mb-1 w-100 text-left ${genre === "amazon-prime-video" && 'active'}`} onClick={() => navigate('/films/amazon-prime-video')}>Amazon Prime Video</button>
                    <button type="button" className={`btn btn-sm btn-light mr-1 mb-1 w-100 text-left ${genre === "netflix" && 'active'}`} onClick={() => navigate('/films/netflix')}>Netflix</button>
                    <button type="button" className={`btn btn-sm btn-light mr-1 mb-1 w-100 text-left ${genre === "disney-plus" && 'active'}`} onClick={() => navigate('/films/disney-plus')}>Disney+&nbsp;</button>
                </ul>
            </div>

            <div className="bg-white px-4 py-3 mb-3">
                <h6 className="font-weight-bold text-dark">Films {streaming && 'du domaine public' || ''} par année</h6>
                <ul className="list-unstyled overflow-auto" style={{maxHeight: 200}}>
                    <button type="button" className={`btn btn-sm btn-light mr-1 mb-1 w-100 text-left ${year === 'tous-les-temps' && 'active'}`} onClick={() => navigate(`/films/meilleurs-films${!notgenres.includes(genre) ? '-'+GENRES_TABLE_FR[genre].toLowerCase():''}-de-tous-les-temps${streaming && '-streaming' || ''}`)}> Tout </button>
                    {years.map(i => (<button type="button" className={`btn btn-sm btn-light mr-1 mb-1 w-100 text-left ${year === i && 'active'}`} onClick={() => navigate(`/films/meilleurs-films${!notgenres.includes(genre) ? '-'+GENRES_TABLE_FR[genre].toLowerCase():''}-de-${i}${streaming && '-streaming' || ''}`)}>{i}</button>))}
                </ul>
            </div>


            <div className="bg-white px-4 py-3 mb-3">
                <h6 className="font-weight-bold text-dark">Films {streaming && 'du domaine public' || ''} par genre</h6>
                <ul className="list-unstyled overflow-auto" style={{maxHeight: 200}}>
                { GENRES_TABLE_FR.map((g, i) => 
                    {
                        if (i === 0) return (
                        <button type="button" className={`btn btn-sm btn-sm btn-light mr-1 mb-1 w-100 text-left ${genre  === "all" && year === 'tous-les-temps' && !streaming && 'active'}`} onClick={() => navigate(`films/meilleurs-films-de-tous-les-temps${streaming && '-streaming' || ''}`) }>
                            Tous
                        </button>
                        )
                        return (
                            <button type="button" className={`btn btn-sm btn-sm btn-light mr-1 mb-1 w-100 text-left ${ genre == i && 'active'}`} onClick={() => navigate(`films/meilleurs-films-${g.toLowerCase()}-de-${year}${streaming && '-streaming' || ''}`) }>
                            {GENRES_TABLE_FR[i]}
                        </button>
                        )
                    }
                )}
                </ul>
            </div>

        
            {/* [EXPLORE] Sidebar */}
            <div className="sticky-top" style={{height: "100vh"}}>
                <AdComponent client="ca-pub-3093835180445554" slot="1816003843" customClasses="mb-3"/> 
            </div>
        </Col>
        
    )
  }
}

export default ExploreSidebar

{/* <div className="bg-white px-4 py-3 mb-3">
    <h6 className="font-weight-bold text-dark">Pires films {streaming && 'du domaine public' || ''}  par genre</h6>
    <ul className="list-unstyled">
    { GENRES_TABLE.map((genre, i) => 
    <li>
        <Link to={`movies/worst-${genre.toLowerCase()}-movies-of-tous-les-temps${streaming && '-streaming' || ''}`} className="text-monospace small text-decoration-none">
        {GENRES_TABLE_FR[i]}
        </Link>
    </li>
    )}
    </ul>
</div> */}